













































import {Component, Prop, Vue} from "vue-property-decorator";
import {Maintenance} from "@/models/maintenance";

@Component({
  components: {
    ImageGalleryComponent: () => import('./dialog/MaintenanceImageGalleryDialog.component.vue')
  }
})
export default class MachineMaintenanceTableRowComponent extends Vue {

  @Prop({default: new Maintenance({})})
  public maintenance!: Maintenance;

  @Prop({default: () => []})
  public selectedRows!: Maintenance[];

  public imageDialog = false;
  public maintenanceListDialog = false;
  public dialogDelete = false;

  public isSelected = false;

  public openImages() {
    this.imageDialog = true
  }

  public closeImageGalleryDialog() {
    this.imageDialog = false;
  }

  public selectedCheckbox() {
    if (this.isSelected) {
      const findIndex = this.selectedRows.findIndex(maintenance => maintenance.id === this.maintenance.id);
      this.selectedRows.splice(findIndex, 1);
      this.isSelected = false;
    } else {
      this.selectedRows.push(this.maintenance);
      this.isSelected = true;
    }
  }

}
